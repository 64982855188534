<div>
  <h1>{{ 'ForgotPassword.Title' | translate }}</h1>

  <h3 class="mt-3">{{ 'ForgotPassword.Description' | translate }}:</h3>

  <div class="mt-2">
    <form [formGroup]="recoveryFormGroup">
      <div>
        <mat-form-field>
          <mat-label>{{ 'Common.EmailPlaceholder' | translate }}</mat-label>
          <input matInput
                 type="email"
                 [formControl]="recoveryFormGroup.controls.email">
          <mat-error *ngIf="recoveryFormGroup.controls.email.errors?.['pattern']">
            {{ 'Common.EmailError' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-3">
        <ng-container *ngIf="!recoveryLinkSent">
          <cool-loading-button
            color="primary"
            [disabled]="!recoveryFormGroup.valid"
            [clickHandler]="sendRecoveryLinkAsync.bind(this)"
          >
            {{ 'ForgotPassword.RecoveryButton' | translate }}
          </cool-loading-button>
        </ng-container>
        <ng-container *ngIf="recoveryLinkSent">
          <div class="text-success">
            {{ 'ForgotPassword.EmailSentMessage' | translate }}
          </div>
        </ng-container>
      </div>
    </form>

    <div class="mt-5">
      <hr />
    </div>

    <div class="mt-5">
      <a [routerLink]="RouteLocations.LoginPage.absoluteUrl">
        {{ 'ForgotPassword.ReturnToLogin' | translate }}
      </a>
    </div>
  </div>
</div>
