<div>
  <h1>{{ 'ResetPassword.Title' | translate }}</h1>

  <div class="mt-4">
    <form [formGroup]="resetPasswordFormGroup">
      <div>
        <mat-form-field>
          <mat-label>{{ 'Common.EmailPlaceholder' | translate }}</mat-label>
          <input matInput
                 type="email"
                 [formControl]="resetPasswordFormGroup.controls.email">

          <mat-error *ngIf="resetPasswordFormGroup.controls.email.errors?.['pattern']">
            {{ 'Common.Errors.ValidEmail' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-3">
        <mat-form-field>
          <mat-label>{{ 'Common.PasswordPlaceholder' | translate }}</mat-label>
          <input matInput
                 type="password"
                 [formControl]="resetPasswordFormGroup.controls.password">
          <mat-error *ngIf="resetPasswordFormGroup.controls.password.errors?.['pattern']">
            {{ 'Common.PasswordError' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-3">
        <cool-loading-button
          color="primary"
          [disabled]="!resetPasswordFormGroup.valid"
          [clickHandler]="resetPasswordAsync.bind(this)"
        >
          {{ 'ResetPassword.ResetButton' | translate }}
        </cool-loading-button>

        <mat-error *ngIf="showResetFailedMessage" class="mt-2">
          {{ 'ResetPassword.ResetFailed' | translate }}
        </mat-error>
      </div>
    </form>
  </div>
</div>
