import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginRouteLocations } from './login-route-locations.enum';

@Injectable()
export class LoginRouteNavigationService {
  constructor(
    private _router: Router,
  ) {}

  public async goToLoginAsync() {
    await this._router.navigateByUrl(LoginRouteLocations.LoginPage.absoluteUrl);
  }

  public async goToRegistrationAsync() {
    await this._router.navigateByUrl(LoginRouteLocations.RegistrationPage.absoluteUrl);
  }

    public async goToRegistrationSuccessfulPageAsync() {
    await this._router.navigateByUrl(LoginRouteLocations.RegistrationSuccessfulPage.absoluteUrl);
  }

  public async goToEmailValidationPageAsync() {
    await this._router.navigateByUrl(LoginRouteLocations.ValidationPage.absoluteUrl);
  }
}
