import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoginRouteLocations } from '../common/routing/login-route-locations.enum';
import { FormBuilder, Validators } from '@angular/forms';
import { EMAIL_REGEX } from '../../../../common/utils/regex.utils';
import { AuthenticationService } from '../common/services/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationsService: AuthenticationService,
  ) { }

  public recoveryFormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
  });

  public RouteLocations = LoginRouteLocations;

  public recoveryLinkSent = false;

  ngOnInit(): void {
    this.recoveryFormGroup.controls.email.enable();
  }

  public async sendRecoveryLinkAsync() {
    if (!this.recoveryFormGroup.valid) {
      return;
    }

    await this._authenticationsService.sendForgotPasswordEmailAsync(this.recoveryFormGroup.value.email!);

    this.recoveryFormGroup.controls.email.disable();
    this.recoveryLinkSent = true;
  }
}
