import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginRouteNavigationService } from '../common/routing/login-route-navigation.service';
import { AuthenticationService } from '../common/services/authentication.service';
import { FederatedLoginService } from '../common/services/federated-login.service';
import { OneTimeAuthToken } from '../../../../common/dto/auth.dto';

@Component({
  selector: 'app-after-login',
  templateUrl: './after-login.component.html',
  styleUrls: ['./after-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AfterLoginComponent implements OnInit {
  constructor(
    private _routeNavigationService: LoginRouteNavigationService,
    private _authenticationsService: AuthenticationService,
    private _federatedLoginService: FederatedLoginService,
    private _activatedRoute: ActivatedRoute,
  ) {}

  public async ngOnInit() {
    const token = <OneTimeAuthToken | undefined>this._activatedRoute.snapshot.queryParams['token'];

    if (!token) {
      await this._routeNavigationService.goToLoginAsync();
    } else {
      const successfulSession = await this._authenticationsService.tryCreateSessionFromOneTimeAuthTokenAsync(token);

      if (successfulSession) {
        await this._federatedLoginService.redirectAfterSuccessfulAuthAsync();
      } else {
        await this._routeNavigationService.goToLoginAsync();
      }
    }
  }
}
