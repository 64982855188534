import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../common/utils/regex.utils';
import { LoginRouteLocations } from '../common/routing/login-route-locations.enum';
import { AuthenticationService } from '../common/services/authentication.service';
import { FederatedLoginService } from '../common/services/federated-login.service';
import { LoginAppStateModel } from '../login-app.state';
import { Store } from '@ngxs/store';
import { LoginRouteNavigationService } from '../common/routing/login-route-navigation.service';
import { ApplicationComponent } from '../../../../common/dto/app.dto';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit {

  constructor(
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _authenticationsService: AuthenticationService,
    private _federatedLoginService: FederatedLoginService,
    private _routeNavigationService: LoginRouteNavigationService,
  ) { }

  public loginFormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
  });

  public showLoginFailedMessage = false;

  public RouteLocations = LoginRouteLocations;

  async ngOnInit() {
    const appState = this._store.selectSnapshot<LoginAppStateModel>((state: any) => state.LoginAppStateModel);

    if (appState.isUserLoggedIn && !appState.session?.user.isEmailValidated) {
      await this._routeNavigationService.goToRegistrationSuccessfulPageAsync();
    }
  }

  public async loginAsync() {
    if (!this.loginFormGroup.valid) {
      return;
    }

    this.showLoginFailedMessage = false;

    try {
      await this._authenticationsService.loginWithPasswordAsync({
        email: this.loginFormGroup.value.email!,
        password: this.loginFormGroup.value.password!,
        component: ApplicationComponent.Login,
      });

      const appState = this._store.selectSnapshot<LoginAppStateModel>((state: any) => state.LoginAppStateModel);

      if (appState.session?.user.isEmailValidated) {
        await this._federatedLoginService.redirectAfterSuccessfulAuthAsync();
      } else {
        await this._routeNavigationService.goToRegistrationSuccessfulPageAsync();
      }

    } catch (e: any) {
      console.error(e);

      this.showLoginFailedMessage = true;
    }
  }

  public async loginWithGoogleAsync() {
    await this._authenticationsService.loginWithGoogleAsync();
  }

  protected readonly faGoogle = faGoogle;
}
