<div>
  <h1>{{ 'RegistrationSuccessful.Title' | translate }}</h1>

  <div class="mt-3">
    <fa-icon icon="envelope" size="3x"></fa-icon>
  </div>

  <div class="mt-3">
    {{ 'RegistrationSuccessful.Description' | translate }}
  </div>

  <div class="mt-3">
    <hr>
  </div>

  <div class="mt-3">
    <button *ngIf="!isResent" mat-button (click)="resendAsync()" color="primary">
      {{ 'RegistrationSuccessful.ResendValidation' | translate }}
    </button>

    <div *ngIf="isResent" class="text-success">
      {{ 'RegistrationSuccessful.ValidationSentAgain' | translate }}
    </div>
  </div>
</div>

