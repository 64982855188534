import { ObjectUtils } from '../utils/object.utils';
import { PricesCurrencyBreakdown } from './subscription-prices.enum';
import { DEFAULT_CURRENCY } from '../dto/visitor.dto';

export enum FeaturePermission {
  Backoffice = 'backoffice',

  HasSubscription = 'has-subscription',

  ListAccountUsers = 'list-account-users',
  ManageAccountUsers = 'manage-account-users',
  InviteAccountUser = 'invite-account-user',

  Backoffice_ListAllAccounts = 'list-all-accounts',
  Backoffice_CreateAccount = 'create-account',
  Backoffice_GetAccountDetails = 'get-account-details',

  Backoffice_ViewAccountSubscriptions = 'view-account-subscriptions',
  Backoffice_ManageAccountSubscriptions = 'manage-account-subscriptions',
  Backoffice_CreateAccountSubscription = 'create-account-subscription',

  Backoffice_ManageBrandCategories = 'manage-brand-categories',
  Backoffice_ActivateBrand = 'activate-brand',
  Backoffice_DisableBrand = 'disable-brand',

  Backoffice_AccountAdvanced = 'account-advanced',
  Backoffice_DeleteAccount = 'delete-account',

  Backoffice_EditAccountFeatures = 'backoffice-edit-account-features',

  Backoffice_ServerFunctions = 'server-functions',
  Backoffice_ChangeTrackingCarts = 'backoffice-change-tracking-carts',
  Backoffice_ManageVisitors = 'backoffice-manage-visitors',
  Backoffice_ManageVisitorDevices = 'backoffice-manage-visitor-devices',
  Backoffice_FeaturePermissionOverridesViewer = 'feature-permission-override-viewer',
  Backoffice_VisitorDebugger = 'visitor-debugger',
  Backoffice_FeaturePermissionOverridesEditor = 'feature-permission-override-editor',
  Backoffice_AlgorithmEditor = 'algorithm-editor',
  Backoffice_SendTestEmails = 'send-test-emails',

  Backoffice_BrandPriorities = 'brand-priorities',

  Backoffice_Dropshipping = 'backoffice-dropshipping',
  Backoffice_DropshippingModify = 'backoffice-dropshipping-modify',

  Backoffice_AddSupportUser = 'add-support-user',

  Backoffice_ManageVisitorDeviceIgnoreRules = 'manage-visitor-device-ignore-rules',

  Backoffice_DownloadAudienceExport = 'backoffice-download-audience-export',

  Backoffice_ManageMonitors = 'backoffice-manage-monitors',

  Brands = 'brands',

  Promotions = 'promotions',

  Permission_ViewSubscription = 'permission-view-subscription',
  Permission_ManageSubscription = 'permission-manage-subscription',

  Partners = 'partners',
  Placements = 'placements',
  Reports = 'reports',
  BrandDiscovery = 'brand-discovery',
  ManageBrands = 'manage-brands',
  ManagePlacements = 'manage-placements',

  ManagePartnerships = 'manage-partnerships',

  ManageProducts = 'manage-products',

  ManagePromotions = 'manage-promotions',

  ViewReports = 'view-reports',

  EditAccountDetails = 'edit-account-details',

  PlacementsOrderConfirmationEmail = 'placements-order-confirmation-email',

  ShopifyAPIIntegration = 'shopify-api-integration',

  ManageDropshipping = 'dropshipping-manage',

  Feature_Payments = 'payments',
  Feature_Payouts = 'feature-payouts',
  Permission_ManagePayments = 'manage-payments',

  Feature_Audiences = 'feature-audiences',
  Permission_AudiencesManage = 'permission-audiences-manage',
  Permission_AudiencesExport = 'permission-audiences-export',

  Feature_1on1Partnerships = 'feature-1on1-partnerships',

  Feature_DropshippingSupplier = 'feature-dropshipping-supplier',
  Feature_DropshippingAffiliate = 'feature-dropshipping-affiliate',

  Feature_Chat = 'feature-chat',

  Feature_IgnoreAccounts = 'feature-ignore-accounts',

  Feature_Discounts = 'feature-discounts',

  Rollout_DiscountEmails = 'rollout-discount-emails',
  Rollout_OptOut = 'rollout-opt-out',
  Rollout_Dropshipping = 'rollout-dropshipping',
  Rollout_Audiences = 'rollout-audiences',
}

export enum FeatureLimitationKey {
  MinGiveAndGetPercentage = 'minGiveAndGetPercentage',

  MinGiveAndGetValue = 'minGiveAndGetValue',

  MonthlyAdditionalImpressions = 'monthlyAdditionalImpressions',

  MaxImpressionsReachedPenalty = 'maxImpressionsReachedPenalty',

  AudienceExportEntryPrice = 'audienceExportEntryPrice',

  AudienceExportMinimumEntries = 'audienceExportMinimumEntries',

  DropshippingSystemCommissionRate = 'dropshippingSystemCommissionRate',

  OneOnOnePartnersLimit = 'oneOnOnePartnersLimit',

  InvestmentValue = 'investmentValue',

  MinROIPercentage = 'minROIPercentage',

  RoiPenaltyLimit = 'roiPenaltyLimit',
  RoiPenalty = 'roiPenalty',

  AdditionalROIImpressionsPushingPenalty = 'additionalROIImpressionsPushingPenalty',
}

export interface FeatureLimitations {
  [FeatureLimitationKey.MinGiveAndGetPercentage]: number;

  [FeatureLimitationKey.MinGiveAndGetValue]: number;

  [FeatureLimitationKey.MonthlyAdditionalImpressions]: number;

  [FeatureLimitationKey.MaxImpressionsReachedPenalty]: number;

  [FeatureLimitationKey.AudienceExportEntryPrice]: PricesCurrencyBreakdown;

  [FeatureLimitationKey.AudienceExportMinimumEntries]: number;

  [FeatureLimitationKey.DropshippingSystemCommissionRate]: number;

  [FeatureLimitationKey.OneOnOnePartnersLimit]: number;

  [FeatureLimitationKey.InvestmentValue]: number;

  [FeatureLimitationKey.MinROIPercentage]: number;

  [FeatureLimitationKey.RoiPenaltyLimit]: number;

  [FeatureLimitationKey.RoiPenalty]: number;

  [FeatureLimitationKey.AdditionalROIImpressionsPushingPenalty]: number;
}

type FeatureLimitationMergeStrategy = 'greater' | 'lesser' | 'lesser-price';

const FeatureLimitationMergeStrategyMappings: {
  [limitation in FeatureLimitationKey]: FeatureLimitationMergeStrategy;
} = {
  [FeatureLimitationKey.MinGiveAndGetPercentage]: 'greater',
  [FeatureLimitationKey.MinGiveAndGetValue]: 'greater',
  [FeatureLimitationKey.MonthlyAdditionalImpressions]: 'greater',
  [FeatureLimitationKey.MaxImpressionsReachedPenalty]: 'lesser',
  [FeatureLimitationKey.AudienceExportEntryPrice]: 'lesser-price',
  [FeatureLimitationKey.AudienceExportMinimumEntries]: 'lesser',
  [FeatureLimitationKey.DropshippingSystemCommissionRate]: 'lesser',
  [FeatureLimitationKey.OneOnOnePartnersLimit]: 'greater',
  [FeatureLimitationKey.InvestmentValue]: 'greater',
  [FeatureLimitationKey.MinROIPercentage]: 'greater',
  [FeatureLimitationKey.RoiPenaltyLimit]: 'greater',
  [FeatureLimitationKey.RoiPenalty]: 'lesser',
  [FeatureLimitationKey.AdditionalROIImpressionsPushingPenalty]: 'greater',
};

export interface FeaturePermissions {
  permissions: FeaturePermission[];

  limitations: FeatureLimitations;
}

export function isFeatureEnabled(key: FeaturePermission, permissions: FeaturePermissions | undefined): boolean {
  if (!permissions?.permissions?.length) {
    return false;
  }

  return permissions.permissions.includes(key);
}

export function isFeatureDisabled(key: FeaturePermission, permissions: FeaturePermissions | undefined): boolean {
  return !isFeatureEnabled(key, permissions);
}

export function isAnyFeatureEnabled(
  permissionKeys: FeaturePermission[],
  permissions: FeaturePermissions | undefined,
): boolean {
  return permissionKeys.some((_) => isFeatureEnabled(_, permissions));
}

export function areFeaturesEnabled(
  permissionKeys: FeaturePermission[],
  permissions: FeaturePermissions | undefined,
): boolean {
  return !permissionKeys.some((_) => !isFeatureEnabled(_, permissions));
}

export function mergeFeatureLimitations(base: FeatureLimitations, limitations: Partial<FeatureLimitations>[]): Readonly<FeatureLimitations> {
  const result = <FeatureLimitations>ObjectUtils.deepCopy(base);

  for (const limitation of limitations) {
    for (const [prop, strategy] of <[FeatureLimitationKey, FeatureLimitationMergeStrategy][]>Object.entries(FeatureLimitationMergeStrategyMappings)) {
      const limitationValue = limitation[prop];

      if (limitationValue === undefined) {
        continue;
      }

      if (
        (strategy === 'greater' && limitationValue > result[prop])
        ||
        (strategy === 'lesser' && limitationValue < result[prop])
        ||
        (strategy === 'lesser-price' && (<PricesCurrencyBreakdown>limitationValue)[DEFAULT_CURRENCY] < (<PricesCurrencyBreakdown>result[prop])[DEFAULT_CURRENCY])
      ) {
        result[prop] = <any>limitationValue;
      }
    }
  }

  return result;
}
