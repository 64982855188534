import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoginAppStateModel } from '../../login-app.state';
import { AuthenticationService } from './authentication.service';
import { ENVIRONMENT, WINDOW } from './injection-tokens';
import { Environment } from '../../../environments/environment.interface';

@Injectable()
export class FederatedLoginService {
  constructor(
    private _store: Store,
    private _authenticationsService: AuthenticationService,
    @Inject(WINDOW) private _window: Window,
    @Inject(ENVIRONMENT) private _environment: Environment,
  ) {}

  public async redirectAfterSuccessfulAuthAsync() {
    const appState = this._store.selectSnapshot<LoginAppStateModel>((state: any) => state.LoginAppStateModel);

    if (!appState.isInitialized || !appState.isUserLoggedIn) {
      throw new Error('Not initialized OR not logged in');
    }

    const oneTimeAuthTokenResponse = await this._authenticationsService.createOneTimeAuthTokenAsync();

    const redirectUrl = new URL(this._environment.appComponentUrls[appState.sourceComponent]);

    redirectUrl.pathname = '/login';
    redirectUrl.searchParams.set('token', oneTimeAuthTokenResponse.token);

    (this._window.top ?? this._window).location.href = redirectUrl.toString();
  }

  public async redirectAfterLogoutAsync() {
    const appState = this._store.selectSnapshot<LoginAppStateModel>((state: any) => state.LoginAppStateModel);

    const redirectUrl = new URL(this._environment.appComponentUrls[appState.sourceComponent]);

    (this._window.top ?? this._window).location.href = redirectUrl.toString();
  }
}
