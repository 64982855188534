import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from './common/services/injection-tokens';
import { LoginAppState, LoginAppStateModel, SetInitParamsAction } from './login-app.state';
import { FederatedLoginService } from './common/services/federated-login.service';
import { Select, Store } from '@ngxs/store';
import { ApplicationComponent } from '../../../common/dto/app.dto';
import { lastValueFrom, Observable } from 'rxjs';
import { NoLoginRedirectUrls } from './common/routing/login-route-locations.enum';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _store: Store,
    private _federatedLoginService: FederatedLoginService,
    private _changeDetector: ChangeDetectorRef,
    @Inject(WINDOW) private _window: Window,
  ) {

  }

  @Select(LoginAppState)
  public appState$!: Observable<LoginAppStateModel>;

  public async ngOnInit() {
    this._router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._window?.scrollTo?.(<any>{ top: 0, left: 0, behavior: 'instant' });
      });

    const appState = this._store.selectSnapshot<LoginAppStateModel>((state: any) => state.LoginAppStateModel);

    const windowUrl = new URL(this._window!.location.toString());

    const appCallParam = this._resolveAppParam(windowUrl);

    if (appCallParam) {
      await lastValueFrom(this._store.dispatch(new SetInitParamsAction(appCallParam)));
    }

    if (!NoLoginRedirectUrls.has(windowUrl.pathname) && appState?.isUserLoggedIn && appState?.session?.user.isEmailValidated) {
      await this._federatedLoginService.redirectAfterSuccessfulAuthAsync();
    }

    this._changeDetector.markForCheck();
  }

  private _resolveAppParam(url: URL): ApplicationComponent | undefined {
    const param = url.searchParams.get('app');

    if (param && (<string[]>Object.values(ApplicationComponent)).includes(param)) {
      return <ApplicationComponent>param;
    }

    return undefined;
  }

  protected readonly ApplicationComponent = ApplicationComponent;
}
