import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';

@UntilDestroy()
@Directive({
  selector: '[loaderContent]',
})
export class LoaderContentDirective implements OnInit, OnChanges {
  private _currentlyShown: 'none' | 'loading' | 'content' = 'none';

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _changeDetector: ChangeDetectorRef,
  ) {}

  @Input()
  public loaderContent: any = undefined;

  public ngOnInit(): void {
    this._setView();
  }

  public ngOnChanges() {
    this._setView();
  }

  private _setView() {
    const shouldShow = !!this.loaderContent;

    if (shouldShow && this._currentlyShown !== 'content') {
      this._viewContainer.clear();

      this._viewContainer.createEmbeddedView(this._templateRef);

      this._currentlyShown = 'content';

      this._changeDetector.markForCheck();
    } else if (!shouldShow && this._currentlyShown !== 'loading') {
      this._viewContainer.clear();

      const componentRef = this._viewContainer.createComponent(LoadingIndicatorComponent);

      componentRef.instance.centered = true;

      this._currentlyShown = 'loading';

      this._changeDetector.markForCheck();
    }
  }
}
