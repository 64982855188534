import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../common/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LoginAppState, LoginAppStateModel } from '../login-app.state';
import { FederatedLoginService } from '../common/services/federated-login.service';

@Component({
  selector: 'app-validation-page',
  templateUrl: './validation-page.component.html',
  styleUrls: ['./validation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationPageComponent implements OnInit {

  constructor(private _activatedRoute: ActivatedRoute,
              private _authenticationService: AuthenticationService,
              private _federatedLoginService: FederatedLoginService,
              private _changeDetector: ChangeDetectorRef) {
  }

  public isLoaded = false;
  public isSuccessful = false;

  public isResent = false;

  @SelectSnapshot(LoginAppState)
  public appState!: LoginAppStateModel;

  public async ngOnInit() {
    if (this.appState.session?.user.isEmailValidated) {
      await this.redirectBackAfterAuthAsync();

      return;
    }

    try {
      const token = this._activatedRoute.snapshot.queryParams['token'];

      if (token) {
        await this._authenticationService.validateEmailAsync(token);

        await this._authenticationService.refreshUserSessionAsync();

        this.isSuccessful = true;

        await this.redirectBackAfterAuthAsync();
      }
    } finally {
      this.isLoaded = true;

      this._changeDetector.markForCheck();
    }
  }

  public async resendAsync() {
    await this._authenticationService.resendValidationEmailAsync();

    this.isResent = true;

    this._changeDetector.markForCheck();
  }

  protected async redirectBackAfterAuthAsync() {
    await this._federatedLoginService.redirectAfterSuccessfulAuthAsync();
  }
}
