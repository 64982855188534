import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../common/services/authentication.service';
import { FederatedLoginService } from '../common/services/federated-login.service';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutPageComponent implements OnInit {
  constructor(
    private _authenticationsService: AuthenticationService,
    private _federatedLoginService: FederatedLoginService,
  ) {}

  public async ngOnInit() {
    await this._authenticationsService.logoutAsync();

    await this._federatedLoginService.redirectAfterLogoutAsync();
  }
}
