import { PlacementType } from '../dto/placement.dto';

const MOCK_BASE_URL = 'https://mockbaseurl.com';
const ABSOLUTE_URL_REGEX = /^(?:[a-z+]+:)?\/\//i;

export abstract class UrlUtils {
  public static addQueryToUrl(url: string, queryParams: { [prop: string]: string | undefined }): string {
    const isRelative = !ABSOLUTE_URL_REGEX.test(url);

    let result = new URL(url, isRelative ? MOCK_BASE_URL : undefined);

    const urlEntries = Object.entries(queryParams);

    for (const [param, val] of urlEntries) {
      if (val) {
        result.searchParams.set(param, val);
      }
    }

    if (isRelative) {
      return result.toString().substring(MOCK_BASE_URL.length + (url.startsWith('/') ? 0 : 1));
    }

    return result.toString();
  }

  public static normalizePartialUrl(partialUrl: string): string {
    if (partialUrl && !partialUrl.startsWith('https://')) {
      // noinspection HttpUrlsUsage
      return `https://${ partialUrl.replace('http://', '') }`;
    }

    return partialUrl;
  }

  public static addDiscountCodeToUrl(url: string, targetPlacementType: PlacementType, discountCode: string): string {
    if (!discountCode) {
      return url;
    }

    const addedUrl = UrlUtils.addQueryToUrl(url, {
      'discount': discountCode,
    });

    switch (targetPlacementType) {
      case PlacementType.Shopify:
        const urlEntity = new URL(addedUrl);

        return `${ urlEntity.origin }/discount/${ discountCode }?redirect=${ encodeURIComponent(addedUrl.substring(urlEntity.origin.length)) }`;
      default:
        return addedUrl;
    }
  }

  public static addUTMParams(url: string, medium: string = 'ohana'): string {
    return UrlUtils.addQueryToUrl(url, {
      'utm_source': 'ohana',
      'utm_medium': medium,
    });
  }

  public static cleanUrl(url: string): string {
    return url.replace(/([^:]\/)\/+/g, '$1');
  }
}