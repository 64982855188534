import { RouteLocationNode } from '../../../../../common/model/route-location-node';

export const LoginRouteLocations = {
  LoginPage: new RouteLocationNode(''),

  LogoutPage: new RouteLocationNode('logout'),

  AfterLogin: new RouteLocationNode('after-login'),

  RegistrationPage: new RouteLocationNode('registration'),
  RegistrationSuccessfulPage: new RouteLocationNode('registration-successful'),

  ValidationPage: new RouteLocationNode('validate'),

  ForgotPassword: new RouteLocationNode('forgot-password'),
  ResetPassword: new RouteLocationNode('reset-password'),

  EmailUnsubscribe: new RouteLocationNode('email-unsubscribe'),
};

export const NoLoginRedirectUrls = new Set<string>([
  LoginRouteLocations.LogoutPage.absoluteUrl,
  LoginRouteLocations.EmailUnsubscribe.absoluteUrl,
]);
