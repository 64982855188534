<div
  *ngIf="(appState$ | async) as appState"
  class="page h-100 text-center content-center p-3 p-md-5"
  [class.style-shop]="appState.sourceComponent === ApplicationComponent.Shop"
>
  <div class="d-inline-block text-center">
    <div [class.pb-3]="appState.sourceComponent !== ApplicationComponent.Shop">
      <app-logo height="40px"
                [color]="appState.sourceComponent === ApplicationComponent.Shop ? 'light' : 'dark'"
      >
      </app-logo>
    </div>

    <ng-container>
      <ng-container *ngIf="appState.sourceComponent === ApplicationComponent.Shop">
        <div class="text-center text-white">
          <div>
            <svg style="height: 40px;" class="w-auto" fill="#fff"
                 viewBox="166.555 182.438 91.365 53.925" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M 50.981 79.945 Q 50.981 79.945 51.032 79.738 Q 51.084 79.531 51.511 79.207 Q 51.938 78.883 52.159 78.76 Q 52.379 78.638 52.463 78.57 Q 52.068 80.604 54.705 80.604 Q 56.783 80.604 59.877 79.308 Q 62.066 78.414 62.97 77.476 Q 63.266 77.185 63.314 76.995 Q 63.361 76.805 63.322 76.693 L 54.566 74.615 Q 54.186 74.526 53.879 74.235 Q 53.309 73.744 53.409 73.342 Q 53.794 71.8 56.295 70.548 Q 59.049 69.185 63.266 68.492 Q 64.221 68.336 64.674 68.314 Q 64.797 68.358 65.068 68.392 Q 65.339 68.425 65.612 68.492 Q 66.266 68.649 66.048 69.073 Q 65.859 69.833 62.843 70.28 Q 58.949 70.839 57.927 71.219 Q 56.905 71.599 56.391 72.001 Q 55.878 72.403 55.716 72.962 Q 55.688 73.073 56.169 73.207 Q 56.649 73.342 57.317 73.531 Q 57.985 73.721 59.046 74.034 Q 61.628 74.794 65.36 75.956 Q 65.461 76.448 65.355 76.872 Q 65.06 78.057 63.402 79.151 Q 61.531 80.38 58.548 81.23 Q 56.074 81.922 54.711 81.922 Q 53.571 81.922 52.644 81.52 Q 51.716 81.118 51.297 80.738 Q 50.878 80.358 50.981 79.945 Z"
                transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
              <path
                d="M 76.04 81.587 Q 75.744 81.967 74.906 81.967 Q 74.068 81.967 74.219 81.364 Q 74.876 78.727 75.01 77.744 Q 75.349 75.576 74.74 75.42 Q 73.209 75.107 72.192 75.107 Q 71.176 75.107 70.363 75.721 Q 69.551 76.336 68.851 77.755 Q 68.15 79.174 67.353 81.744 L 65.633 81.744 Q 65.995 80.47 66.268 79.375 Q 66.541 78.28 66.872 76.772 Q 67.204 75.263 67.582 73.476 Q 67.983 71.42 68.337 70.001 Q 68.691 68.582 69.165 67.398 Q 69.494 66.973 69.919 66.973 Q 70.433 66.973 70.631 67.342 Q 70.83 67.71 70.78 68.001 L 69.749 71.688 Q 69.231 73.766 69.175 74.794 Q 71.521 73.453 73.666 73.453 Q 74.359 73.453 75.113 73.565 Q 76.572 74.436 76.84 75.42 Q 76.958 75.934 76.88 76.515 Q 76.802 77.096 76.641 77.833 Q 76.14 80.38 76.04 81.587 Z"
                transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
              <path
                d="M 78.408 77.654 Q 79.065 75.017 81.746 73.409 Q 83.873 72.135 86.488 71.867 Q 86.622 71.867 87.113 71.867 Q 87.605 71.867 88.359 72.157 Q 90.036 72.783 90.31 74.369 Q 90.422 74.995 90.314 75.431 Q 90.205 75.867 90.138 76.045 Q 89.442 78.839 86.918 80.448 Q 84.584 81.922 81.634 81.922 Q 79.511 81.922 78.673 80.939 Q 77.834 79.956 78.408 77.654 Z M 79.816 78.191 Q 79.47 79.576 80.085 80.068 Q 80.555 80.425 81.873 80.425 Q 83.192 80.425 84.247 80.224 Q 85.303 80.023 86.208 79.531 Q 88.151 78.459 88.714 76.202 Q 89.048 74.861 88.545 74.012 Q 87.991 73.096 86.717 73.096 Q 85.807 73.252 84.88 73.431 Q 83.952 73.61 83.042 74.124 Q 81.071 75.219 79.816 78.191 Z"
                transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
              <path
                d="M 94.04 72.939 Q 97.341 71.889 99.553 71.889 Q 102.369 71.889 102.94 73.364 Q 103.191 74.057 102.991 74.861 Q 102.712 75.978 101.713 77.163 Q 100.714 78.347 99.312 79.308 Q 96.057 81.52 92.057 82.056 Q 91.996 82.302 91.871 82.85 Q 91.745 83.397 91.589 84.068 Q 91.434 84.738 91.255 85.498 Q 91.077 86.257 90.927 86.95 Q 90.565 88.671 90.537 88.917 Q 90.509 89.162 90.47 89.319 Q 90.331 89.877 89.904 90.157 Q 89.476 90.436 88.895 90.436 Q 88.784 90.436 88.672 90.436 L 92.794 72.47 Q 93.067 72.18 93.397 72.202 Q 93.727 72.224 93.889 72.381 Q 94.051 72.537 94.04 72.939 Z M 100.37 73.721 Q 99.727 73.342 98.889 73.342 Q 98.051 73.342 97.186 73.498 Q 95.219 73.855 94.019 75.085 Q 93.472 75.665 93.26 76.515 Q 93.238 76.604 93.216 76.693 L 92.252 80.559 Q 97.111 79.531 99.49 77.699 Q 100.869 76.649 101.148 75.531 Q 101.443 74.347 100.37 73.721 Z"
                transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
            </svg>
          </div>

          <div class="mt-3">
            <svg style="max-height: 100px" viewBox="28.382 79.635 243.06 70.628" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(-.80877 -.91616)">
                <rect x="33.582" y="87.152" width="57.968" height="57.968" rx="10" ry="10" fill="rgba(216, 216, 216, 0)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="8.79"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m35.169 95.819q0.549 0.117 0.882 0.544 0.333 0.428 0.333 0.977 0 0.792-0.554 1.255-0.553 0.464-1.543 0.464h-2.943v-6.318h2.844q0.963 0 1.507 0.441 0.545 0.441 0.545 1.197 0 0.558-0.293 0.927-0.292 0.369-0.778 0.513zm-2.286-0.522h1.008q0.378 0 0.58-0.167 0.203-0.166 0.203-0.49t-0.203-0.495q-0.202-0.171-0.58-0.171h-1.008zm1.134 2.52q0.387 0 0.598-0.176 0.212-0.175 0.212-0.508t-0.221-0.522q-0.22-0.189-0.607-0.189h-1.116v1.395h1.134z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m38.784 92.741v3.78q0 0.567 0.279 0.873t0.819 0.306 0.828-0.306 0.288-0.873v-3.78h1.539v3.771q0 0.846-0.36 1.431t-0.967 0.882q-0.608 0.297-1.355 0.297t-1.336-0.293q-0.59-0.292-0.932-0.882-0.342-0.589-0.342-1.435v-3.771z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m49.026 92.741-2.187 4.23v2.088h-1.539v-2.088l-2.187-4.23h1.746l1.224 2.646 1.215-2.646z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m12.533 108.12q-0.693 0-1.242-0.225t-0.878-0.666q-0.328-0.441-0.346-1.062h1.638q0.036 0.351 0.243 0.535 0.207 0.185 0.54 0.185 0.342 0 0.54-0.158 0.198-0.157 0.198-0.436 0-0.234-0.158-0.387-0.157-0.153-0.387-0.252-0.229-0.099-0.652-0.225-0.612-0.189-0.999-0.378t-0.666-0.558-0.279-0.963q0-0.882 0.639-1.382 0.639-0.499 1.665-0.499 1.044 0 1.683 0.499 0.639 0.5 0.684 1.391h-1.665q-0.018-0.306-0.225-0.482-0.207-0.175-0.531-0.175-0.279 0-0.45 0.148-0.171 0.149-0.171 0.428 0 0.306 0.288 0.477t0.9 0.369q0.612 0.207 0.994 0.396 0.383 0.189 0.662 0.549t0.279 0.927q0 0.54-0.275 0.981-0.274 0.441-0.796 0.702t-1.233 0.261z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m17.289 101.74v3.78q0 0.567 0.279 0.873t0.819 0.306 0.828-0.306 0.288-0.873v-3.78h1.539v3.771q0 0.846-0.36 1.431t-0.968 0.882q-0.607 0.297-1.354 0.297t-1.337-0.293q-0.589-0.292-0.931-0.882-0.342-0.589-0.342-1.435v-3.771z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m24.407 108.12q-0.693 0-1.242-0.225t-0.877-0.666q-0.329-0.441-0.347-1.062h1.638q0.036 0.351 0.243 0.535 0.207 0.185 0.54 0.185 0.342 0 0.54-0.158 0.198-0.157 0.198-0.436 0-0.234-0.157-0.387-0.158-0.153-0.387-0.252-0.23-0.099-0.653-0.225-0.612-0.189-0.999-0.378t-0.666-0.558-0.279-0.963q0-0.882 0.639-1.382 0.639-0.499 1.665-0.499 1.044 0 1.683 0.499 0.639 0.5 0.684 1.391h-1.665q-0.018-0.306-0.225-0.482-0.207-0.175-0.531-0.175-0.279 0-0.45 0.148-0.171 0.149-0.171 0.428 0 0.306 0.288 0.477t0.9 0.369q0.612 0.207 0.995 0.396 0.382 0.189 0.661 0.549t0.279 0.927q0 0.54-0.274 0.981-0.275 0.441-0.797 0.702t-1.233 0.261z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m32.196 101.74v1.233h-1.674v5.085h-1.539v-5.085h-1.674v-1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m36.906 106.94h-2.358l-0.378 1.116h-1.611l2.286-6.318h1.782l2.286 6.318h-1.629zm-0.396-1.188-0.783-2.313-0.774 2.313z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m41.143 101.74v6.318h-1.539v-6.318z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m47.908 108.06h-1.539l-2.574-3.897v3.897h-1.539v-6.318h1.539l2.574 3.915v-3.915h1.539z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m52.959 106.94h-2.358l-0.378 1.116h-1.611l2.286-6.318h1.782l2.286 6.318h-1.629zm-0.396-1.188-0.783-2.313-0.774 2.313z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m59.481 104.82q0.549 0.117 0.882 0.544 0.333 0.428 0.333 0.977 0 0.792-0.553 1.255-0.554 0.464-1.544 0.464h-2.943v-6.318h2.844q0.963 0 1.508 0.441 0.544 0.441 0.544 1.197 0 0.558-0.292 0.927-0.293 0.369-0.779 0.513zm-2.286-0.522h1.008q0.378 0 0.581-0.167 0.202-0.166 0.202-0.49t-0.202-0.495q-0.203-0.171-0.581-0.171h-1.008zm1.134 2.52q0.387 0 0.599-0.176 0.211-0.175 0.211-0.508t-0.22-0.522q-0.221-0.189-0.608-0.189h-1.116v1.395h1.134z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m63.124 106.87h2.016v1.188h-3.555v-6.318h1.539z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.51 24.517)" d="m67.414 102.97v1.278h2.061v1.188h-2.061v1.386h2.331v1.233h-3.87v-6.318h3.87v1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
              </g>
              <g transform="translate(87.546 -.88149)">
                <rect x="33.582" y="87.152" width="57.968" height="57.968" rx="10" ry="10" fill="rgba(216, 216, 216, 0)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="8.79"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m29.97 93.974v1.278h2.061v1.188h-2.061v1.386h2.331v1.233h-3.87v-6.318h3.87v1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m37.227 97.943h-2.358l-0.378 1.116h-1.611l2.286-6.318h1.782l2.286 6.318h-1.629zm-0.396-1.188-0.783-2.313-0.774 2.313z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m43.147 99.059-1.314-2.385h-0.369v2.385h-1.539v-6.318h2.583q0.747 0 1.273 0.261 0.527 0.261 0.788 0.715 0.261 0.455 0.261 1.013 0 0.63-0.356 1.125-0.355 0.495-1.048 0.702l1.458 2.502zm-1.683-3.474h0.954q0.423 0 0.634-0.207 0.212-0.207 0.212-0.585 0-0.36-0.212-0.567-0.211-0.207-0.634-0.207h-0.954z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m51.445 99.059h-1.539l-2.574-3.897v3.897h-1.539v-6.318h1.539l2.574 3.915v-3.915h1.539z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m30.388 108.12q-0.693 0-1.242-0.225t-0.877-0.666q-0.329-0.441-0.347-1.062h1.638q0.036 0.351 0.243 0.535 0.207 0.185 0.54 0.185 0.342 0 0.54-0.158 0.198-0.157 0.198-0.436 0-0.234-0.157-0.387-0.158-0.153-0.387-0.252-0.23-0.099-0.653-0.225-0.612-0.189-0.999-0.378t-0.666-0.558-0.279-0.963q0-0.882 0.639-1.382 0.639-0.499 1.665-0.499 1.044 0 1.683 0.499 0.639 0.5 0.684 1.391h-1.665q-0.018-0.306-0.225-0.482-0.207-0.175-0.531-0.175-0.279 0-0.45 0.148-0.171 0.149-0.171 0.428 0 0.306 0.288 0.477t0.9 0.369q0.612 0.207 0.995 0.396 0.382 0.189 0.661 0.549t0.279 0.927q0 0.54-0.274 0.981-0.275 0.441-0.797 0.702t-1.233 0.261z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m39.104 101.74v6.318h-1.539v-2.601h-2.394v2.601h-1.539v-6.318h1.539v2.475h2.394v-2.475z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m43.199 108.12q-0.891 0-1.634-0.414-0.742-0.414-1.179-1.157-0.436-0.742-0.436-1.669t0.436-1.665q0.437-0.738 1.179-1.152 0.743-0.414 1.634-0.414t1.633 0.414q0.743 0.414 1.17 1.152 0.428 0.738 0.428 1.665t-0.432 1.669q-0.432 0.743-1.17 1.157t-1.629 0.414zm0-1.404q0.756 0 1.21-0.504 0.455-0.504 0.455-1.332 0-0.837-0.455-1.337-0.454-0.499-1.21-0.499-0.765 0-1.22 0.495-0.454 0.495-0.454 1.341 0 0.837 0.454 1.336 0.455 0.5 1.22 0.5z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m52.098 103.78q0 0.549-0.252 1.003-0.252 0.455-0.774 0.734t-1.296 0.279h-0.954v2.268h-1.539v-6.318h2.493q0.756 0 1.278 0.261t0.783 0.72 0.261 1.053zm-2.439 0.792q0.441 0 0.657-0.207t0.216-0.585-0.216-0.585-0.657-0.207h-0.837v1.584h0.837z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m21.418 113.89q0-0.936 0.405-1.67 0.405-0.733 1.13-1.143 0.724-0.409 1.642-0.409 1.125 0 1.926 0.594t1.071 1.62h-1.692q-0.189-0.396-0.535-0.603-0.347-0.207-0.788-0.207-0.711 0-1.152 0.495t-0.441 1.323 0.441 1.323 1.152 0.495q0.441 0 0.788-0.207 0.346-0.207 0.535-0.603h1.692q-0.27 1.026-1.071 1.615-0.801 0.59-1.926 0.59-0.918 0-1.642-0.41-0.725-0.409-1.13-1.138t-0.405-1.665z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m31.757 117.06-1.314-2.385h-0.369v2.385h-1.539v-6.318h2.583q0.747 0 1.274 0.261 0.526 0.261 0.787 0.715 0.261 0.455 0.261 1.013 0 0.63-0.355 1.125-0.356 0.495-1.049 0.702l1.458 2.502zm-1.683-3.474h0.954q0.423 0 0.635-0.207 0.211-0.207 0.211-0.585 0-0.36-0.211-0.567-0.212-0.207-0.635-0.207h-0.954z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m35.934 111.97v1.278h2.061v1.188h-2.061v1.386h2.331v1.233h-3.87v-6.318h3.87v1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m41.633 110.74q0.999 0 1.746 0.396t1.156 1.111q0.41 0.716 0.41 1.652 0 0.927-0.41 1.647-0.409 0.72-1.161 1.116-0.751 0.396-1.741 0.396h-2.367v-6.318h2.367zm-0.099 4.986q0.873 0 1.359-0.477t0.486-1.35-0.486-1.359-1.359-0.486h-0.729v3.672h0.729z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m47.349 110.74v6.318h-1.539v-6.318z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m53.007 110.74v1.233h-1.674v5.085h-1.539v-5.085h-1.674v-1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <path transform="matrix(.90138 0 0 .90138 26.517 21.04)" d="m56.061 117.12q-0.693 0-1.242-0.225t-0.877-0.666q-0.329-0.441-0.347-1.062h1.638q0.036 0.351 0.243 0.535 0.207 0.185 0.54 0.185 0.342 0 0.54-0.158 0.198-0.157 0.198-0.436 0-0.234-0.157-0.387-0.158-0.153-0.387-0.252-0.23-0.099-0.653-0.225-0.612-0.189-0.999-0.378t-0.666-0.558-0.279-0.963q0-0.882 0.639-1.382 0.639-0.499 1.665-0.499 1.044 0 1.683 0.499 0.639 0.5 0.684 1.391h-1.665q-0.018-0.306-0.225-0.482-0.207-0.175-0.531-0.175-0.279 0-0.45 0.148-0.171 0.149-0.171 0.428 0 0.306 0.288 0.477t0.9 0.369q0.612 0.207 0.995 0.396 0.382 0.189 0.661 0.549t0.279 0.927q0 0.54-0.274 0.981-0.275 0.441-0.797 0.702t-1.233 0.261z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                <g transform="translate(87.788 .399)">
                  <rect x="33.582" y="87.152" width="57.968" height="57.968" rx="10" ry="10" fill="rgba(216, 216, 216, 0)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="8.79"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m36.17 94.739q-0.171-0.315-0.49-0.482-0.32-0.166-0.752-0.166-0.747 0-1.197 0.49-0.45 0.491-0.45 1.31 0 0.873 0.473 1.363 0.472 0.491 1.3 0.491 0.567 0 0.959-0.288 0.391-0.288 0.571-0.828h-1.953v-1.134h3.348v1.431q-0.171 0.576-0.58 1.071-0.41 0.495-1.04 0.801t-1.422 0.306q-0.936 0-1.669-0.41-0.734-0.409-1.143-1.138-0.41-0.729-0.41-1.665t0.41-1.67q0.409-0.733 1.138-1.143 0.729-0.409 1.665-0.409 1.134 0 1.913 0.549 0.778 0.549 1.03 1.521z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m40.371 93.974v1.278h2.061v1.188h-2.061v1.386h2.331v1.233h-3.87v-6.318h3.87v1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m48.24 92.741v1.233h-1.674v5.085h-1.539v-5.085h-1.674v-1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m17.831 102.97v1.278h2.061v1.188h-2.061v1.386h2.331v1.233h-3.87v-6.318h3.87v1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m25.025 108.06-1.287-1.935-1.134 1.935h-1.746l2.025-3.213-2.07-3.105h1.791l1.269 1.908 1.116-1.908h1.746l-2.007 3.186 2.088 3.132z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m27.325 104.89q0-0.936 0.405-1.67 0.405-0.733 1.13-1.143 0.724-0.409 1.642-0.409 1.125 0 1.926 0.594t1.071 1.62h-1.692q-0.189-0.396-0.535-0.603-0.347-0.207-0.788-0.207-0.711 0-1.152 0.495t-0.441 1.323 0.441 1.323 1.152 0.495q0.441 0 0.788-0.207 0.346-0.207 0.535-0.603h1.692q-0.27 1.026-1.071 1.615-0.801 0.59-1.926 0.59-0.918 0-1.642-0.41-0.725-0.409-1.13-1.138t-0.405-1.665z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m35.981 106.87h2.016v1.188h-3.555v-6.318h1.539z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m40.253 101.74v3.78q0 0.567 0.279 0.873t0.819 0.306 0.828-0.306 0.288-0.873v-3.78h1.539v3.771q0 0.846-0.36 1.431t-0.967 0.882q-0.608 0.297-1.355 0.297t-1.336-0.293q-0.59-0.292-0.932-0.882-0.342-0.589-0.342-1.435v-3.771z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m47.363 108.12q-0.693 0-1.242-0.225t-0.877-0.666q-0.329-0.441-0.347-1.062h1.638q0.036 0.351 0.243 0.535 0.207 0.185 0.54 0.185 0.342 0 0.54-0.158 0.198-0.157 0.198-0.436 0-0.234-0.157-0.387-0.158-0.153-0.387-0.252-0.23-0.099-0.653-0.225-0.612-0.189-0.999-0.378t-0.666-0.558-0.279-0.963q0-0.882 0.639-1.382 0.639-0.499 1.665-0.499 1.044 0 1.683 0.499 0.639 0.5 0.684 1.391h-1.665q-0.018-0.306-0.225-0.482-0.207-0.175-0.531-0.175-0.279 0-0.45 0.148-0.171 0.149-0.171 0.428 0 0.306 0.288 0.477t0.9 0.369q0.612 0.207 0.995 0.396 0.382 0.189 0.661 0.549t0.279 0.927q0 0.54-0.274 0.981-0.275 0.441-0.797 0.702t-1.233 0.261z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m52.155 101.74v6.318h-1.539v-6.318z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m59.199 101.74-2.241 6.318h-1.926l-2.241-6.318h1.638l1.566 4.77 1.575-4.77z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m61.377 102.97v1.278h2.061v1.188h-2.061v1.386h2.331v1.233h-3.87v-6.318h3.87v1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m16.558 110.74q0.999 0 1.746 0.396t1.156 1.111q0.41 0.716 0.41 1.652 0 0.927-0.41 1.647-0.409 0.72-1.161 1.116-0.751 0.396-1.741 0.396h-2.367v-6.318h2.367zm-0.099 4.986q0.873 0 1.359-0.477t0.486-1.35-0.486-1.359-1.359-0.486h-0.729v3.672h0.729z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m22.265 110.74v6.318h-1.539v-6.318z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m25.664 117.12q-0.693 0-1.242-0.225t-0.877-0.666q-0.329-0.441-0.347-1.062h1.638q0.036 0.351 0.243 0.535 0.207 0.185 0.54 0.185 0.342 0 0.54-0.158 0.198-0.157 0.198-0.436 0-0.234-0.157-0.387-0.158-0.153-0.387-0.252-0.23-0.099-0.653-0.225-0.612-0.189-0.999-0.378t-0.666-0.558-0.279-0.963q0-0.882 0.639-1.382 0.639-0.499 1.665-0.499 1.044 0 1.683 0.499 0.639 0.5 0.684 1.391h-1.665q-0.018-0.306-0.225-0.482-0.207-0.175-0.531-0.175-0.279 0-0.45 0.148-0.171 0.149-0.171 0.428 0 0.306 0.288 0.477t0.9 0.369q0.612 0.207 0.995 0.396 0.382 0.189 0.661 0.549t0.279 0.927q0 0.54-0.274 0.981-0.275 0.441-0.797 0.702t-1.233 0.261z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m28.656 113.89q0-0.936 0.405-1.67 0.405-0.733 1.129-1.143 0.725-0.409 1.643-0.409 1.125 0 1.926 0.594t1.071 1.62h-1.692q-0.189-0.396-0.536-0.603-0.346-0.207-0.787-0.207-0.711 0-1.152 0.495t-0.441 1.323 0.441 1.323 1.152 0.495q0.441 0 0.787-0.207 0.347-0.207 0.536-0.603h1.692q-0.27 1.026-1.071 1.615-0.801 0.59-1.926 0.59-0.918 0-1.643-0.41-0.724-0.409-1.129-1.138t-0.405-1.665z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m38.761 117.12q-0.891 0-1.634-0.414-0.742-0.414-1.179-1.157-0.436-0.742-0.436-1.669t0.436-1.665q0.437-0.738 1.179-1.152 0.743-0.414 1.634-0.414t1.633 0.414q0.743 0.414 1.17 1.152 0.428 0.738 0.428 1.665t-0.432 1.669q-0.432 0.743-1.17 1.157t-1.629 0.414zm0-1.404q0.756 0 1.21-0.504 0.455-0.504 0.455-1.332 0-0.837-0.455-1.337-0.454-0.499-1.21-0.499-0.765 0-1.22 0.495-0.454 0.495-0.454 1.341 0 0.837 0.454 1.336 0.455 0.5 1.22 0.5z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m44.358 110.74v3.78q0 0.567 0.279 0.873t0.819 0.306 0.828-0.306 0.288-0.873v-3.78h1.539v3.771q0 0.846-0.36 1.431t-0.968 0.882q-0.607 0.297-1.354 0.297t-1.337-0.293q-0.589-0.292-0.931-0.882-0.342-0.589-0.342-1.435v-3.771z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m54.842 117.06h-1.539l-2.574-3.897v3.897h-1.539v-6.318h1.539l2.574 3.915v-3.915h1.539z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m60.496 110.74v1.233h-1.674v5.085h-1.539v-5.085h-1.674v-1.233z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path transform="matrix(.90138 0 0 .90138 26.58 20.663)" d="m63.559 117.12q-0.693 0-1.242-0.225t-0.878-0.666q-0.328-0.441-0.346-1.062h1.638q0.036 0.351 0.243 0.535 0.207 0.185 0.54 0.185 0.342 0 0.54-0.158 0.198-0.157 0.198-0.436 0-0.234-0.158-0.387-0.157-0.153-0.387-0.252-0.229-0.099-0.652-0.225-0.612-0.189-0.999-0.378t-0.666-0.558-0.279-0.963q0-0.882 0.639-1.382 0.639-0.499 1.665-0.499 1.044 0 1.683 0.499 0.639 0.5 0.684 1.391h-1.665q-0.018-0.306-0.225-0.482-0.207-0.175-0.531-0.175-0.279 0-0.45 0.148-0.171 0.149-0.171 0.428 0 0.306 0.288 0.477t0.9 0.369q0.612 0.207 0.994 0.396 0.383 0.189 0.662 0.549t0.279 0.927q0 0.54-0.275 0.981-0.274 0.441-0.796 0.702t-1.233 0.261z" fill="#fff" stroke-width="0px" style="text-transform:uppercase"/>
                  <path d="m-80.575 112.81h14.017v-4.5l8.354 8-8.354 8v-4.5h-14.017v-7z" fill="rgba(216, 216, 216, 0)" stroke="#fff"/>
                  <path d="m7.218 112.38h14.017v-4.5l8.354 8-8.354 8v-4.5h-14.017v-7z" fill="rgba(216, 216, 216, 0)" stroke="#fff"/>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="card-box mt-4 px-3 px-md-5 py-4" style="min-width: 350px">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
