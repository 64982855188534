import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginRouteLocations } from './common/routing/login-route-locations.enum';
import { LoginPageComponent } from './login-page/login-page.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import {
  RegistrationSuccessfulPageComponent,
} from './registration-successful-page/registration-successful-page.component';
import { LoggedInRouteGuard } from './common/routing/route-guards/logged-in.guard';
import { ValidationPageComponent } from './validation-page/validation-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { EmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { AfterLoginComponent } from './after-login/after-login.component';

const routes: Routes = [
  {
    path: LoginRouteLocations.LoginPage.relativeUrl,
    component: LoginPageComponent,
    pathMatch: 'full',
  },
  {
    path: LoginRouteLocations.LogoutPage.relativeUrl,
    component: LogoutPageComponent,
  },
  {
    path: LoginRouteLocations.AfterLogin.relativeUrl,
    component: AfterLoginComponent,
  },
  {
    path: LoginRouteLocations.RegistrationPage.relativeUrl,
    component: RegistrationPageComponent,
  },
  {
    path: LoginRouteLocations.RegistrationSuccessfulPage.relativeUrl,
    component: RegistrationSuccessfulPageComponent,
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: LoginRouteLocations.ValidationPage.relativeUrl,
    component: ValidationPageComponent,
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: LoginRouteLocations.ForgotPassword.relativeUrl,
    component: ForgotPasswordComponent,
  },
  {
    path: LoginRouteLocations.ResetPassword.relativeUrl,
    component: ResetPasswordComponent,
  },
  {
    path: LoginRouteLocations.EmailUnsubscribe.relativeUrl,
    component: EmailUnsubscribeComponent,
  },
  {
    path: '**',
    redirectTo: LoginRouteLocations.LoginPage.relativeUrl,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
