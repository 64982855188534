import { BrandDTO, BrandId, BrandMediumDTO, BrandSocialType } from './brand.dto';
import { ProductDTO, ProductId } from './product.dto';
import {
  PlacementComponentSettingsDTO,
  PlacementComponentType,
  PlacementId,
  ShopDiscountDisplaySettingsDTO,
} from './placement.dto';
import { EmailAddress, IpAddress, UserAgent } from './users.dto';
import { TrackingEventType } from '../model/tracking-event-type.enum';
import { PromotionId, PromotionMinimalDTO } from './promotions.dto';

export const VISITOR_DEVICE_ID_URL_PARAM = 'ovdid';
export const VISITOR_DEVICE_ID_TIME_URL_PARAM = 'ovdidt';

export const MAX_SHOWN_PARTNERS = 3;
export const MAX_DISCOUNT_DISPLAY_SHOWN_PARTNERS = 5;

export const DISCOUNT_DISPLAY_DEFAULT_TITLE = 'On purchase, get Rewards from other Sustainable brands we love';

export type WidgetDisplayId = string & { __WidgetDisplayId: string };

export type VisitorDeviceThumbprint = string & { __VisitorDeviceThumbprint: string };
export type VisitorDeviceDynamicThumbprint = string & { __VisitorDeviceDynamicThumbprint: string };

export interface BrandsWidgetDisplayContentDTO {
  id: WidgetDisplayId;

  placementComponentType: PlacementComponentType;

  brands: WidgetDisplayContentBrandDetailsDTO[];

  placementComponentSettings: PlacementComponentSettingsDTO | undefined;
}

export interface WidgetDisplayContentBrandDetailsDTO {
  brand: BrandDTO;

  products: ProductDTO[];

  promotions: PromotionMinimalDTO[];
}

export interface BrandsDisplayRequestParamsDTO {
  placementId: PlacementId;

  visitorDeviceId: VisitorDeviceId;

  placementComponentType: PlacementComponentType;

  orderId: OrderId | undefined;

  noTracking: boolean | undefined;
}

export interface DiscountWidgetDisplayContentDTO {
  id: WidgetDisplayId;

  brands: BrandMediumDTO[];

  placementComponentSettings: ShopDiscountDisplaySettingsDTO | undefined;
}

export interface DiscountWidgetDisplayRequestParamsDTO {
  placementId: PlacementId;

  visitorDeviceId: VisitorDeviceId;

  noTracking: boolean | undefined;
}

export type OrderId = string & { __OrderId: string; };
export type ShopOrderId = string & { __ShopOrderId: string; };

export type VisitorId = string & { __VisitorId: string; };
export type VisitorDeviceId = string & { __VisitorDeviceId: string; };

export interface VisitorDevicePlatform {
  browser: {
    name: string | undefined;
    version: string | undefined;
    major: string | undefined;
  };

  device: {
    model: string | undefined;
    type: string | undefined;
    vendor: string | undefined;
  };

  os: {
    name: string | undefined;
    version: string | undefined;
  };
}

export enum VisitorDeviceType {
  Browser = 'browser',

  Email = 'email',
}

export interface CheckoutRequestParamsDTO {
  placementId: PlacementId;

  visitorDeviceId: VisitorDeviceId;

  orderId: OrderId;

  shopOrderId: ShopOrderId | undefined;

  price: Price | undefined;

  currency: Currency | undefined;

  date: DateString | undefined;

  externalProductIds: string[] | undefined;

  email: EmailAddress | undefined;
}

export interface DeviceResolverRequestDTO {
  placementId: PlacementId | undefined;
  visitorDeviceAId: VisitorDeviceId;
  visitorDeviceBId: VisitorDeviceId;

  thumbprint: string | undefined;

  userAgent: string | undefined;

  reason: string | undefined;
}

export type Price = number & { __Price: string; };

export type DateString = string & { __DateString: string; };

export type ISODateString = string & { __ISODateString: string; };

export enum Currency {
  GBP = 'GBP',

  USD = 'USD',
}

export const DEFAULT_CURRENCY = Currency.GBP;

export enum CurrencySign {
  GBP_Pound = '£',
  USD_Dollar = '$',
}

export const CurrencySigns: {
  readonly [sign in CurrencySign]: Currency;
} = {
  [CurrencySign.GBP_Pound]: Currency.GBP,
  [CurrencySign.USD_Dollar]: Currency.USD,
};

export const CurrencyConversion: {
  readonly [currency in Currency]: {
    readonly [currency in Currency]: {
      multiplier: number;
    };
  };
} = {
  [Currency.GBP]: {
    [Currency.GBP]: { multiplier: 1 },
    [Currency.USD]: { multiplier: 1.25 },
  },
  [Currency.USD]: {
    [Currency.GBP]: { multiplier: 0.8 },
    [Currency.USD]: { multiplier: 1 },
  },
};

export const CurrencySignCurrency: {
  readonly [currency in Currency]: CurrencySign;
} = Object.entries(CurrencySigns).reduce((res, [sign, currency]) => {
  res[currency] = <CurrencySign>sign;

  return res;
}, <{
  [currency in Currency]: CurrencySign;
}>{});


export enum EmailContentPartType {
  WidgetTop = 'widget-top',

  WidgetBottom = 'widget-bottom',

  Brand = 'brand',

  BrandBottom = 'brand-bottom',

  Product = 'product',
}

export type VisitorDeviceIgnoreRuleId = string & { __VisitorDeviceIgnoreRuleId: string; };

export interface VisitorDeviceIgnoreRuleDTO {
  id: VisitorDeviceIgnoreRuleId;

  type: VisitorDeviceIgnoreRuleType;

  value: VisitorDeviceId | IpAddress;

  name: string;
}

export enum VisitorDeviceIgnoreRuleType {
  VisitorDeviceId = 'id',

  VisitorDeviceIpAddress = 'ip-address',
}

export type VisitorActivityType =
  TrackingEventType.BrandClick
  | TrackingEventType.BrandSocialClick
  | TrackingEventType.ProductClick
  | TrackingEventType.PromotionClick
  | TrackingEventType.WidgetSeen
  | TrackingEventType.PageBottomBannerClicked
  | TrackingEventType.ThanksPanelButtonClicked
  | TrackingEventType.DiscountCodeCopied;

export interface VisitorActivityTrackingData {
  brandId?: BrandId | undefined;
  productId?: ProductId | undefined;
  promotionId?: PromotionId | undefined;
  socialType?: BrandSocialType | undefined;
}

export interface VisitorActivityTrackingDTO {
  visitorDeviceId: VisitorDeviceId;
  placementId: PlacementId;
  placementComponentType: PlacementComponentType;
  displayId: WidgetDisplayId;
  type: VisitorActivityType;
  data: VisitorActivityTrackingData;
  noTracking: boolean | undefined;
}

export interface VisitorDeviceCreateRequestDTO {
  thumbprint: string | undefined;

  userAgent: UserAgent | undefined;
}

export const WIDGET_ATTRIBUTE = 'data-ohana-widget';
export const WIDGET_DATA_ATTRIBUTE = 'data-ohana-widget-data';
