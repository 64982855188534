<div class="text-center">
  <h1>{{ 'EmailUnsubscribe.Title' | translate }}</h1>
  <div class="mt-3">{{ 'EmailUnsubscribe.Subtitle' | translate }}</div>

  <div class="mt-3">
    <ng-container *ngIf="status !== 'success'">
      <div>
        <cool-loading-button
          [disabled]="!hasToken"
          color="primary"
          [clickHandler]="unsubscribeEmailAsync.bind(this)"
        >
          {{ 'EmailUnsubscribe.UnsubscribeButton' | translate }}
        </cool-loading-button>
      </div>

      <div *ngIf="!hasToken" class="mt-2">
        <mat-hint>{{ 'EmailUnsubscribe.MissingToken' | translate }}</mat-hint>
      </div>

      <div *ngIf="status === 'failed'" class="mt-2">
        <mat-error>{{ 'Common.Failed' | translate }}</mat-error>
      </div>
    </ng-container>

    <ng-container *ngIf="status === 'success'">
      <h2>{{ 'EmailUnsubscribe.SuccessMessage' | translate }}</h2>
    </ng-container>
  </div>
</div>

