import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoginAppStateModel } from '../../../login-app.state';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { LoginRouteNavigationService } from '../login-route-navigation.service';

@Injectable()
export class EmailValidatedRouteGuardLogic {
  constructor(
    private _store: Store,
    private _routeNavigationService: LoginRouteNavigationService,
  ) {}

  public canActivate(): Observable<boolean> {
    return this._store
      .select<LoginAppStateModel>((state) => state.LoginAppStateModel)
      .pipe(
        filter((state: LoginAppStateModel) => !!state?.isInitialized),
        take(1),
        map((applicationState: LoginAppStateModel) => {
          const isValidated = !!applicationState?.session?.user.isEmailValidated;

          if (!isValidated) {
            this._routeNavigationService.goToEmailValidationPageAsync();
          }

          return isValidated;
        }),
      );
  }
}

export const EmailValidatedRouteGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(EmailValidatedRouteGuardLogic).canActivate();
};
