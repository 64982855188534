import { UrlUtils } from '../utils/url.utils';

type RouteLocationObject = { [prop: symbol]: RouteLocationNode };

export class RouteLocationNode<TChildren extends RouteLocationObject = any> {
  private _absoluteUrl?: string;

  constructor(
    public relativeUrl: string,
    public children: TChildren = <any>{},
  ) {
    if (children) {
      for (const child of Object.values<RouteLocationNode>(children)) {
        child.setParent(this);
      }
    }
  }

  public parent: RouteLocationNode | undefined;

  public get absoluteUrl(): string {
    if (!this._absoluteUrl) {
      this._absoluteUrl = (this.parent?.absoluteUrl || '') + '/' + this.relativeUrl;
    }

    return this._absoluteUrl;
  }

  public getAbsoluteUrlWithParams(params: { [prop: string]: string }, query?: { [prop: string]: string | undefined }) {
    let result = this.absoluteUrl;

    for (const [param, val] of Object.entries(params)) {
      result = result.replace(':' + param, val);
    }

    if (query) {
      result = UrlUtils.addQueryToUrl(result, query);
    }

    return result;
  }

  public getAbsoluteUrlWithQuery(query: { [prop: string]: string | undefined }) {
    return UrlUtils.addQueryToUrl(this.absoluteUrl, query);
  }

  public setParent(parent: RouteLocationNode) {
    this.parent = parent;
  }
}
