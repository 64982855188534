<div *loaderContent="isLoaded">
  <ng-container *ngIf="isSuccessful">
    <h1>{{ 'ValidationPage.SuccessfulTitle' | translate }}</h1>

    <div class="mt-3 text-success">
      <fa-icon icon="check-circle" size="3x"></fa-icon>
    </div>

    <div class="mt-3">
      <button mat-raised-button color="primary" (click)="redirectBackAfterAuthAsync()">
        {{ 'ValidationPage.GoToDashboard' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!isSuccessful">
    <h1>{{ 'ValidationPage.FailedTitle' | translate }}</h1>

    <div class="mt-3 text-danger">
      <fa-icon icon="times-circle" size="3x"></fa-icon>
    </div>

    <div class="mt-3">
      {{ 'ValidationPage.FailedDescription' | translate }}
    </div>

    <div class="mt-3">
      <cool-loading-button *ngIf="!isResent" [clickHandler]="resendAsync.bind(this)" color="primary">
        {{ 'ValidationPage.ResendValidation' | translate }}
      </cool-loading-button>

      <div *ngIf="isResent" class="text-success">
        {{ 'ValidationPage.ValidationSentAgain' | translate }}
      </div>
    </div>
  </ng-container>
</div>

