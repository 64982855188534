export enum ApplicationComponent {
  App = 'app',

  Shop = 'shop',

  BackOffice = 'bo',

  Login = 'login',
}

export const DEFAULT_APPLICATION_COMPONENT = ApplicationComponent.Shop;