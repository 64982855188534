import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../common/services/authentication.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LoginAppState, LoginAppStateModel } from '../login-app.state';
import { FederatedLoginService } from '../common/services/federated-login.service';

@Component({
  selector: 'app-registration-successful-page',
  templateUrl: './registration-successful-page.component.html',
  styleUrls: ['./registration-successful-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationSuccessfulPageComponent implements OnInit {

  constructor(private _authenticationService: AuthenticationService,
              private _federatedLoginService: FederatedLoginService,
              private _changeDetector: ChangeDetectorRef) {
  }

  public isResent = false;

  @SelectSnapshot(LoginAppState)
  public appState!: LoginAppStateModel;

  public async ngOnInit() {
    if (this.appState?.session?.user.isEmailValidated) {
      await this._federatedLoginService.redirectAfterSuccessfulAuthAsync();

      return;
    }
  }

  public async resendAsync() {
    await this._authenticationService.resendValidationEmailAsync();

    this.isResent = true;

    this._changeDetector.markForCheck();
  }
}
