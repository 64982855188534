import { Environment } from './environment.interface';
import versionInfo from '../../../version.json';
import { ApplicationComponent } from '../../../common/dto/app.dto';

export const environment: Environment = {
  production: true,

  version: versionInfo.version,

  serverUrl: 'https://api.joinohana.xyz',

  logging: {
    enabled: false,
  },

  tracking: {
    enabled: false,
  },

  links: {
    termsUrl: 'https://www.joinohana.io/terms',
    privacyUrl: 'https://www.joinohana.io/privacy',
    websiteUrl: 'https://www.joinohana.io',
    blogUrl: 'https://blog.joinohana.io',
    faqUrl: 'https://www.joinohana.io/faq',
    supportEmail: 'support@joinohana.io',
  },

  appComponentUrls: {
    [ApplicationComponent.App]: 'https://app.joinohana.xyz',
    [ApplicationComponent.Shop]: 'https://shop.joinohana.xyz',
    [ApplicationComponent.BackOffice]: 'https://backoffice.joinohana.xyz',
    [ApplicationComponent.Login]: 'https://login.joinohana.xyz',
  },
};
