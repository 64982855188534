import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailUnsubscribeToken } from '../../../../common/dto/emails.dto';
import { EmailUnsubscribeService } from '../common/services/email-unsubscribe.service';

@Component({
  selector: 'app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailUnsubscribeComponent implements OnInit {
  private _token?: EmailUnsubscribeToken;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _emailUnsubscribeService: EmailUnsubscribeService,
    private _changeDetector: ChangeDetectorRef,
  ) {
  }

  public status: 'ready' | 'success' | 'failed' = 'ready';

  protected get hasToken(): boolean {
    return !!this._token;
  }

  public async ngOnInit() {
    this._token = this._activatedRoute.snapshot.queryParams['token'];
  }

  public async unsubscribeEmailAsync() {
    if (!this._token) {
      this._changeDetector.markForCheck();

      return;
    }

    try {
      await this._emailUnsubscribeService.unsubscribeEmailAsync(this._token);

      this.status = 'success';
    } catch (e: any) {
      console.error(e);

      this.status = 'failed';
    } finally {
      this._changeDetector.markForCheck();
    }
  }
}
