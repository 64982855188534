import { BrandId } from './brand.dto';
import { Base64Image, FileDTO } from './file.dto';

export type PlacementId = string & { __PlacementId: string };

export interface PlacementDTO {
  id: PlacementId;

  isActivated: boolean;

  brandId: BrandId | undefined;

  type: PlacementType;
}

// Do not change values as these are used in the database
export enum PlacementType {
  Shopify = 'shopify',

  WooCommerce = 'woocommerce',

  Custom = 'custom',
}

export type PlacementComponentId = string & { __PlacementComponentId: string };

export interface PlacementComponentDTO<T extends PlacementComponentSettingsBaseDTO = PlacementComponentSettingsDTO> {
  id: PlacementComponentId;

  placementId: PlacementId;
  type: PlacementComponentType;

  displayType: PlacementComponentDisplayType;

  isActivated: boolean;

  settings: T;
}

export enum PlacementComponentType {
  OrderStatusPage = 'checkout-widget',

  OrderConfirmationEmail = 'order-confirmation-email',

  CustomBrandsDisplay = 'custom-brands-display',

  ShopDiscountDisplay = 'shop-discount-display',

  ShopCreditEmail = 'shop-credit-email',
}

// Do not change values as these are used in the database
export enum PlacementComponentDisplayType {
  Full = 1,

  Medium = 2,

  Minimal = 3,
}

export type PlacementComponentSettingsDTO =
  OrderStatusPageSettingsDTO
  | OrderConfirmationEmailSettingsDTO
  | CustomBrandsDisplaySettingsDTO
  | ShopDiscountDisplaySettingsDTO
  | ShopCreditEmailSettingsDTO;

export interface PlacementComponentSettingsBaseDTO {
  displayType: PlacementComponentDisplayType;
}

export interface CustomBrandsDisplaySettingsDTO extends PlacementComponentSettingsBaseDTO {

}

export interface ShopDiscountDisplaySettingsDTO extends PlacementComponentSettingsBaseDTO {
  title: string | undefined;

  titleFontSize: string | undefined;
}

export interface ShopCreditEmailSettingsDTO extends PlacementComponentSettingsBaseDTO {
  sendCreditClaimEmail: boolean;
}

export interface OrderStatusPageSettingsDTO extends PlacementComponentSettingsBaseDTO {
  title: string | undefined;

  thanksPanel: {
    enabled: boolean;

    text: string | undefined;

    buttonText: string | undefined;
    buttonColor: string | undefined;
    buttonTextColor: string | undefined;
  };
}

export interface OrderConfirmationEmailSettingsDTO extends PlacementComponentSettingsBaseDTO {
  title: string | undefined;

  titlePreviewImage: Base64Image | FileDTO | undefined;
}