import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoggerService } from './services/logger/logger.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthenticationService } from './services/authentication.service';
import { LoginRouteNavigationService } from './routing/login-route-navigation.service';
import { ENVIRONMENT, WINDOW } from './services/injection-tokens';
import { environment } from '../../environments/environment';
import { CoolHttpModule } from '@angular-cool/http';
import { CoolStorageModule } from '@angular-cool/storage';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from './services/translation/translation.service';
import { StartupService, startupServiceFactory } from './services/startup/startup.service';
import { GlobalErrorHandler } from './services/logger/global-error-handler';
import { DateProvider } from './services/date.provider';
import { UnauthenticatedInterceptor } from './services/http/unauthenticated.interceptor';
import { LoggedInRouteGuardLogic } from './routing/route-guards/logged-in.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EmailValidatedRouteGuardLogic } from './routing/route-guards/email-validated.guard';
import { FeaturePermissionEnabledDirective } from './directives/feature-permission-enabled.directive';
import { FeaturePermissionsRouteGuardLogic } from './routing/route-guards/feature-permissions.guard';
import { LoaderContentDirective } from './directives/loader-content.directive';
import { LoggedInRegisterRouteGuardLogic } from './routing/route-guards/logged-in-register.guard';
import { FeaturePermissionsEnabledDirective } from './directives/feature-permissions-enabled.directive';
import { FeatureDisabledDirective } from './directives/feature-disabled.directive';
import { MatButtonModule } from '@angular/material/button';
import { CoolLoadingButtonModule } from '@angular-cool/loading-button';
import { FederatedLoginService } from './services/federated-login.service';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { EmailUnsubscribeService } from './services/email-unsubscribe.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    FontAwesomeModule,

    CoolHttpModule,
    CoolStorageModule,
    CoolLoadingButtonModule,

    MatButtonModule,

    TranslateModule.forChild(),

    NgOptimizedImage,

    LoadingIndicatorComponent,
  ],
  declarations: [
    FeaturePermissionEnabledDirective,
    FeatureDisabledDirective,
    FeaturePermissionsEnabledDirective,
    LoaderContentDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    FontAwesomeModule,

    NgOptimizedImage,

    MatButtonModule,
    CoolHttpModule,
    CoolStorageModule,

    CoolLoadingButtonModule,

    TranslateModule,

    FeaturePermissionEnabledDirective,
    FeatureDisabledDirective,
    FeaturePermissionsEnabledDirective,
    LoaderContentDirective,
  ],
})
export class AppCommonModule {
  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        LoggerService,
        LoginRouteNavigationService,
        AuthenticationService,
        EmailUnsubscribeService,

        DateProvider,

        TranslationService,
        StartupService,
        UnauthenticatedInterceptor,

        LoggedInRouteGuardLogic,
        LoggedInRegisterRouteGuardLogic,
        EmailValidatedRouteGuardLogic,
        FeaturePermissionsRouteGuardLogic,

        FederatedLoginService,

        ...CoolHttpModule.forRoot().providers as Provider[],
        ...CoolStorageModule.forRoot().providers as Provider[],

        { provide: ENVIRONMENT, useValue: environment },
        { provide: WINDOW, useValue: window },
        { provide: ErrorHandler, useClass: environment.logging?.enabled ? GlobalErrorHandler : ErrorHandler },
        { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService], multi: true },
      ],
    };
  }
}
