import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoginAppState, LoginAppStateModel } from '../../login-app.state';
import {
  FeaturePermission,
  FeaturePermissions, isFeatureDisabled,
  isFeatureEnabled,
} from '../../../../../common/model/feature-permissions.enum';

@UntilDestroy()
@Directive({
  selector: '[featurePermissionEnabled]',
})
export class FeaturePermissionEnabledDirective implements OnInit, OnChanges {
  private _featurePermissions?: FeaturePermissions;
  private _isInView = false;

  constructor(
    private _element: ElementRef,
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _changeDetector: ChangeDetectorRef,
  ) {}

  @Input()
  public featurePermissionEnabled?: keyof typeof FeaturePermission;

  @Input()
  public featureEnabledNegated: boolean = false;

  @Select(LoginAppState)
  public applicationState$!: Observable<LoginAppStateModel>;

  public ngOnInit() {
    this.applicationState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this._featurePermissions = state.session?.permissions;

      this._setView();
    });
  }

  public ngOnChanges() {
    this._setView();
  }

  private _setView() {
    const shouldShow =
      this.featurePermissionEnabled && (this.featureEnabledNegated ? isFeatureDisabled(FeaturePermission[this.featurePermissionEnabled], this._featurePermissions) : isFeatureEnabled(FeaturePermission[this.featurePermissionEnabled], this._featurePermissions));

    if (shouldShow && !this._isInView) {
      this._viewContainer.clear();

      this._viewContainer.createEmbeddedView(this._templateRef);

      this._isInView = true;

      this._changeDetector.markForCheck();
    } else if (!shouldShow && this._isInView) {
      this._viewContainer.clear();

      this._isInView = false;

      this._changeDetector.markForCheck();
    }
  }
}
