import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../common/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../common/utils/regex.utils';
import { FederatedLoginService } from '../common/services/federated-login.service';
import { LoginAppState, LoginAppStateModel } from '../login-app.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { ApplicationComponent } from '../../../../common/dto/app.dto';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationsService: AuthenticationService,
    private _federatedLoginService: FederatedLoginService,
    private _activatedRoute: ActivatedRoute,
  ) { }

  @Select(LoginAppState)
  public appState$!: Observable<LoginAppStateModel>;

  public resetPasswordFormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
  });

  public showResetFailedMessage = false;

  public ngOnInit(): void {
  }

  public async resetPasswordAsync() {
    if (!this.resetPasswordFormGroup.valid) {
      return;
    }

    const resetToken = this._activatedRoute.snapshot.queryParams['token'];

    if (!resetToken) {
      this.showResetFailedMessage = true;

      return;
    }

    this.showResetFailedMessage = false;

    try {
      await this._authenticationsService.resetPasswordAsync({
        email: this.resetPasswordFormGroup.value.email!,
        newPassword: this.resetPasswordFormGroup.value.password!,
        token: resetToken,
      });

      await this._authenticationsService.loginWithPasswordAsync({
        email: this.resetPasswordFormGroup.value.email!,
        password: this.resetPasswordFormGroup.value.password!,
        component: ApplicationComponent.Login,
      });

      await this._federatedLoginService.redirectAfterSuccessfulAuthAsync();
    } catch {
      this.showResetFailedMessage = true;
    }
  }
}
