<div>
  <h1>{{ 'RegistrationPage.Title' | translate }}</h1>

  <div class="mt-4">
    <form [formGroup]="registrationFormGroup">
      <div>
        <mat-form-field>
          <mat-label>{{ 'Common.EmailPlaceholder' | translate }}</mat-label>
          <input matInput
                 type="email"
                 [formControl]="registrationFormGroup.controls.email">
          <mat-error *ngIf="registrationFormGroup.controls.email.errors?.['pattern']">
            {{ 'Common.EmailError' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-2">
        <mat-form-field>
          <mat-label>{{ 'Common.PasswordPlaceholder' | translate }}</mat-label>
          <input matInput
                 type="password"
                 [formControl]="registrationFormGroup.controls.password">
          <mat-error *ngIf="registrationFormGroup.controls.password.errors?.['pattern']">
            {{ 'Common.PasswordError' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-2">
        <mat-checkbox color="primary" data-test-id="consent" [formControl]="registrationFormGroup.controls.consent">
            {{ 'RegistrationPage.BySigningUp' | translate }}
            <a [href]="termsUrl" target="_blank" (click)="$event.stopPropagation()">
              {{ 'RegistrationPage.BySigningUpTerms' | translate }}
            </a>
            {{ 'RegistrationPage.Acknowledge' | translate }}
            <a [href]="privacyUrl" target="_blank" (click)="$event.stopPropagation()">
              {{ 'RegistrationPage.AcknowledgePrivacy' | translate }}
            </a>.
        </mat-checkbox>
      </div>

      <div class="mt-3">
        <cool-loading-button
          color="primary"
          class="w-100"
          [disabled]="!registrationFormGroup.valid"
          [clickHandler]="registerAsync.bind(this)"
        >
          {{ 'RegistrationPage.RegistrationButton' | translate }}
        </cool-loading-button>

        <mat-error *ngIf="showRegistrationFailedMessage" class="mt-2">
          {{ 'RegistrationPage.RegistrationFailed' | translate }}
        </mat-error>
      </div>
    </form>

    <div class="text-center my-3">
      {{ 'Common.SocialLoginSeparator' | translate }}
    </div>

    <div>
      <button mat-raised-button
              class="button-google content-center w-100"
              [disabled]="!registrationFormGroup.controls.consent.getRawValue()"
              (click)="registerWithGoogleAsync()"
      >
        <fa-icon [icon]="faGoogle"></fa-icon>

        <span class="ms-2">{{ 'Common.ContinueWithGoogle' | translate }}</span>
      </button>
    </div>

    <div class="mt-5">
      <hr />
    </div>

    <div class="mt-5">
      <a [routerLink]="RouteLocations.LoginPage.absoluteUrl">
        {{ 'RegistrationPage.GoToLoginAccount' | translate }}
      </a>
    </div>
  </div>
</div>
