import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserSession } from '../../../common/dto/user-session.dto';
import { ApplicationComponent } from '../../../common/dto/app.dto';

export interface LoginAppStateModel {
  isInitialized: boolean;

  isUserLoggedIn: boolean;

  session: UserSession | undefined;

  sourceComponent: ApplicationComponent;
}

export interface LoginAppStateModelDTO {
  sourceComponent: ApplicationComponent;
}

export class SetIsInitializedAction {
  static readonly type = 'Set Is initialized Action';
}

export class SetInitParamsAction {
  static readonly type = 'Set Init params Action';

  constructor(
    public sourceComponent: ApplicationComponent,
  ) {}
}

export class SetSessionAction {
  static readonly type = 'Set Session Action';

  constructor(public session: UserSession | undefined) {
  }
}

export const DEFAULT_LOGIN_APP_STATE_MODEL: Readonly<LoginAppStateModel> = {
  isInitialized: false,
  isUserLoggedIn: false,
  session: undefined,
  sourceComponent: ApplicationComponent.Shop,
};

@State<LoginAppStateModel>({
  name: 'LoginAppStateModel',
  defaults: DEFAULT_LOGIN_APP_STATE_MODEL,
})
@Injectable()
export class LoginAppState {
  @Action(SetSessionAction)
  setSession(ctx: StateContext<LoginAppStateModel>, action: SetSessionAction) {
    return ctx.patchState({
      isUserLoggedIn: action.session !== undefined,
      session: action.session,
    });
  }

  @Action(SetInitParamsAction)
  setInitParams(ctx: StateContext<LoginAppStateModel>, action: SetInitParamsAction) {
    return ctx.patchState({
      sourceComponent: action.sourceComponent,
    });
  }

  @Action(SetIsInitializedAction)
  setInitialized(ctx: StateContext<LoginAppStateModel>) {
    return ctx.patchState({
      isInitialized: true,
    });
  }

  @Selector()
  static isUserLoggedIn(state: LoginAppStateModel) {
    return state.isUserLoggedIn;
  }

  @Selector()
  static session(state: LoginAppStateModel) {
    return state.session;
  }

  public static serialize(state: LoginAppStateModel): string {
    return JSON.stringify({
      sourceComponent: state.sourceComponent,
    } satisfies LoginAppStateModelDTO);
  }

  public static deserialize(serialized: string): LoginAppStateModel {
    let deserialized: undefined | LoginAppStateModelDTO;

    try {
      deserialized = JSON.parse(serialized);
    } catch {

    }

    return <LoginAppStateModel>Object.assign({}, DEFAULT_LOGIN_APP_STATE_MODEL, deserialized ?? {});
  }
}
