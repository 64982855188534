import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCommonModule } from './common/common.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { LoginAppState } from './login-app.state';
import { environment } from '../environments/environment';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { setInjector } from './common/utils/injector.utils';
import { LoginPageComponent } from './login-page/login-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { ValidationPageComponent } from './validation-page/validation-page.component';
import {
  RegistrationSuccessfulPageComponent,
} from './registration-successful-page/registration-successful-page.component';
import { faCheckCircle, faCircle, faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LogoComponent } from './common/components/logo/logo.component';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { LoadingIndicatorComponent } from './common/components/loading-indicator/loading-indicator.component';
import { EmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { AfterLoginComponent } from './after-login/after-login.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', `.json?v=${ encodeURIComponent(environment.version) }`);
}

@NgModule({
  declarations: [
    AppComponent,

    LoginPageComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegistrationPageComponent,
    ValidationPageComponent,
    RegistrationSuccessfulPageComponent,
    LogoutPageComponent,
    EmailUnsubscribeComponent,
    AfterLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    BrowserAnimationsModule,

    AppCommonModule.forRoot(),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    NgxsModule.forRoot([
      LoginAppState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: LoginAppState,
      serialize: LoginAppState.serialize,
      deserialize: LoginAppState.deserialize,
    }),

    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,

    LogoComponent,
    LoadingIndicatorComponent,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    injector: Injector,
    faLibrary: FaIconLibrary,
  ) {
    setInjector(injector);

    faLibrary.addIcons(
      faCircle,
      faTimesCircle,
      faCheckCircle,
      faEnvelope,
    );
  }
}
