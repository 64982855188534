import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Languages } from '../../../../../../common/model/languages.enum';

@UntilDestroy()
@Injectable()
export class TranslationService {
  private _currentLanguage$ = new ReplaySubject<Languages>(1);

  constructor(private _translate: TranslateService) {
  }

  public get currentLanguage$(): Observable<Languages> {
    return this._currentLanguage$.asObservable();
  }

  public get currentLanguage(): Languages {
    return <Languages>this._translate.currentLang;
  }

  public getAsync(key: string, interpolateParams?: Object): Promise<string> {
    return firstValueFrom(this._translate.get(key, interpolateParams));
  }

  public setup() {
    this._translate.addLangs([Languages.English]);
    this._translate.setDefaultLang(Languages.English);

    const browserLang = <Languages>this._translate.getBrowserLang();
    let chosenLanguage = Object.values(<any>Languages).includes(browserLang) ? browserLang : Languages.English;

    this._translate.use(chosenLanguage);

    this._currentLanguage$.next(chosenLanguage);

    this._translate.onLangChange
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((event) => {
        this._currentLanguage$.next(<Languages>event.lang);
      });
  }

  public async switchLanguageAsync(language: Languages) {
    this._translate.use(language);
  }
}
