<div>
  <h1>{{ 'LoginPage.Title' | translate }}</h1>

  <div class="mt-4">
    <form [formGroup]="loginFormGroup">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Common.EmailPlaceholder' | translate }}</mat-label>
          <input matInput
                 type="email"
                 [formControl]="loginFormGroup.controls.email">
          <mat-error *ngIf="loginFormGroup.controls.email.errors?.['pattern']">
            {{ 'Common.EmailError' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-2">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Common.PasswordPlaceholder' | translate }}</mat-label>
          <input matInput
                 type="password"
                 [formControl]="loginFormGroup.controls.password">
          <mat-error *ngIf="loginFormGroup.controls.password.errors?.['pattern']">
            {{ 'Common.PasswordError' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-3">
        <cool-loading-button
          color="primary"
          class="w-100"
          [disabled]="!loginFormGroup.valid"
          [clickHandler]="loginAsync.bind(this)"
        >
          {{ 'LoginPage.LoginButton' | translate }}
        </cool-loading-button>

        <mat-error *ngIf="showLoginFailedMessage" class="mt-2">
          {{ 'LoginPage.LoginFailed' | translate }}
        </mat-error>
      </div>
    </form>

    <div class="text-center my-3">
      {{ 'Common.SocialLoginSeparator' | translate }}
    </div>

    <div>
      <button mat-raised-button
              class="button-google content-center w-100"
              (click)="loginWithGoogleAsync()"
      >
        <fa-icon [icon]="faGoogle"></fa-icon>

        <span class="ms-2">{{ 'Common.ContinueWithGoogle' | translate }}</span>
      </button>
    </div>

    <div class="mt-5">
      <hr />
    </div>

    <div class="mt-5 flex-row-center justify-content-evenly">
      <a [routerLink]="RouteLocations.ForgotPassword.absoluteUrl">
        {{ 'LoginPage.ForgotPasswordLink' | translate }}
      </a>
      <div class="mx-3">
        <fa-icon icon="circle" class="fs-9 vertical-align-middle"></fa-icon>
      </div>
      <a [routerLink]="RouteLocations.RegistrationPage.absoluteUrl">
        {{ 'LoginPage.RegistrationLink' | translate }}
      </a>
    </div>
  </div>
</div>
