import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../common/services/authentication.service';
import { LoginRouteNavigationService } from '../common/routing/login-route-navigation.service';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../common/utils/regex.utils';
import { LoginRouteLocations } from '../common/routing/login-route-locations.enum';
import { ENVIRONMENT } from '../common/services/injection-tokens';
import { Environment } from '../../environments/environment.interface';
import { ActivatedRoute } from '@angular/router';
import { EmailAddress } from '../../../../common/dto/users.dto';

import { ApplicationComponent, DEFAULT_APPLICATION_COMPONENT } from '../../../../common/dto/app.dto';
import { LoginAppState, LoginAppStateModel } from '../login-app.state';
import { Select } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationPageComponent implements OnInit {

  constructor(
    @Inject(ENVIRONMENT) private _environment: Environment,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _authenticationsService: AuthenticationService,
    private _routeNavigationService: LoginRouteNavigationService,
  ) { }

  @Select(LoginAppState)
  public appState$!: Observable<LoginAppStateModel>;

  public registrationFormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
    consent: [false, Validators.requiredTrue],
  });

  public showRegistrationFailedMessage = false;

  public RouteLocations = LoginRouteLocations;

  public termsUrl = this._environment.links.termsUrl;
  public privacyUrl = this._environment.links.privacyUrl;

  ngOnInit(): void {
  }

  public async registerAsync() {
    if (!this.registrationFormGroup.valid) {
      return;
    }

    const appState = await firstValueFrom(this.appState$);

    this.showRegistrationFailedMessage = false;

    try {
      await this._authenticationsService.registerWithPasswordAsync({
        email: <EmailAddress>this.registrationFormGroup.value.email!,
        password: this.registrationFormGroup.value.password!,
        referrer: this._activatedRoute.snapshot.queryParams['referrer'],
        source: appState.sourceComponent ?? DEFAULT_APPLICATION_COMPONENT,
      });

      await this._routeNavigationService.goToRegistrationSuccessfulPageAsync();
    } catch {
      this.showRegistrationFailedMessage = true;
    }
  }

  public async registerWithGoogleAsync() {
    await this._authenticationsService.registerWithGoogleAsync();
  }

  protected readonly faGoogle = faGoogle;
}
